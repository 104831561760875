<template>
    <div>
        <b-dropdown variant="light-danger" right v-b-tooltip.hover title="Envoyer un mail" no-caret class="mr-1" toggle-class="btn-icon" size="md">
            <template #button-content>
                <i class="fas" :class="load ? 'fa-spin fa-spinner fa-size' : 'fa-envelope fa-size'"></i>
            </template>
            <b-dropdown-item v-for="(m,i) in mails"  :key="i"  @click="preview(m)">{{m.titre}}</b-dropdown-item>
        </b-dropdown>
        <b-modal id="previewTpl" ref="previewTpl" hide-footer title="Aperçu avant envoi"  size="xl">      
            <p class="h6"></p>
            <div class="d-block">
                <b-row>                    
                    <b-col md="4">
                        <b-form-group label="Email destinataire">
                            <b-form-input type="email" v-model="email"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Objet du mail">
                            <b-form-input v-model="titre"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Ajouter en copie">
                            <b-form-input type="email" v-model="cc"></b-form-input>
                        </b-form-group>
                    </b-col>   
                    <b-col md="12">
                        <b-form-group label="Annotation">
                            <b-form-textarea v-model="annotation" rows="4"></b-form-textarea>
                        </b-form-group>
                    </b-col>    
                    <b-col md="12">
                        <b-form-group label="Ajoutez vos fichiers">
                            <b-form-file multiple @change="handleFile" placeholder="Choisissez votre document" drop-placeholder="Deposez ici"></b-form-file>
                        </b-form-group>
                    </b-col>  
                    <b-col md="12" v-if="files.length > 0">
                        <b-table-simple hover>    
                            <b-thead>                
                                <b-th>Nom du fichier</b-th>
                                <b-th>Taille</b-th>
                                <b-th>Action</b-th>                                
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="(f, i) in files" :key="i">        
                                    <b-td>
                                        <b-form-input type="text" v-model="f.name"></b-form-input>
                                    </b-td>
                                    <b-td>
                                        <b-form-input :value="size(f.size)" readonly></b-form-input>
                                    </b-td>
                                    <b-td>
                                        <b-button-group>
                                            <b-button @click="removeFile(i)" variant="light-danger" class="btn-icon" size="sm"><i class="fas fa-trash"></i></b-button>                                            
                                        </b-button-group>
                                    </b-td>                                                      
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>           
                </b-row>    
                <div class="d-block text-center mb-5">
                    <b-button @click="sendMail" variant="light-primary" class="text-white mt-5" :class="load ? 'btn-icon' : ''">
                        <span v-if="!load">Envoyez</span>
                        <i v-else class="fa fa-spin fa-spinner text-white"></i>
                    </b-button>
                </div>
                <b-embed type="iframe" aspect="4by3" style="background: #e3e7ff;padding-top: 50px;" :srcdoc="tpl.html" allowfullscreen></b-embed>        
            </div>      
        </b-modal>
    </div>
</template>
<script>
export default {
    name: "Mail",
    props:['link', 'obj', 'extrat', 'type', 'client_id'],
    components: {        
    },
    beforeCreate() {
    },
    computed:{        
        mails(){
            var tpl = JSON.parse(JSON.stringify(this.$api.params.Template));
            var type = this.type+"Mail"
            if(this.type === "Projet" && this.obj.status_id === 1 && this.obj.etude.min_budget === null && this.obj.etude.max_budget === null){
                return tpl.filter(t => t.type === type && t.id != 3);
            }
            var res = tpl.filter(t => t.type === type);            
            if(this.type === 'Contrat' && this.$parent.bia?.etude_only === true){                
                var t = res.find(y => y.id === 22);
                t.titre = 'Signature de votre résiliation et de votre étude personnalisée';
            }
            return res;
        }
    },
    watch:{        
    },
    data() {
        return {            
            load: false,  
            email: false,
            cc: null,
            titre: null,
            annotation: null,
            tpl: {
                id:null,
                html:null,
                titre: null
            },
            files: []                   
        }
    },
    methods: {               
        preview(tpl){    
            tpl = JSON.parse(JSON.stringify(tpl));
            this.tpl.id = tpl.id;
            this.tpl.titre = tpl.titre;
            this.obj.link = this.link;    
            this.email = tpl.email === undefined ? this.obj.email : tpl.email;                             
            this.titre = `${tpl.titre} - ${this.obj.prenom} ${this.obj.nom}`
            var params = {
                template_id: tpl.id,
                params:{
                    titre: tpl.titre,                    
                    projet: this.obj,
                    client: this.obj,
                    user: this.$api.user,
                    extrat: this.extrat
                }
            }
            this.$api.ajax('/tpl/preview', params, res => {
                if(res.status === true){
                    this.tpl.html = res.data;
                    this.$refs.previewTpl.show();
                }
            })
        },
        sendMail(){
            if(this.load === true){
                return false;
            }      
            this.load = true;
            var obj = JSON.parse(JSON.stringify(this.obj));            
            obj.link = this.link;                    
            var params = {
                template_id:this.tpl.id,
                to:{          
                    nom:this.obj.nom,
                    prenom:this.obj.prenom,
                    email: this.email
                },
                titre: this.titre,
                cc: this.cc,
                params:{          
                    client: obj,
                    projet: obj,
                    user: this.$api.user,
                    extrat: this.extrat,
                    annotation: this.annotation
                },
                client_id: this.client_id,
                files: this.files
            }
            this.$api.ajax('/tpl/mail', params, res => {
                if(res.status === true){
                    this.load = false;
                    this.$bvToast.toast('Votre mail a bien ete envoyee', {
                        title: 'Sauvegarder',
                        variant: 'success',
                        solid: true
                    });
                    this.$refs.previewTpl.hide();
                    this.email = false
                    this.cc = null;
                    this.titre = null;
                    this.annotation = null;
                    this.tpl = {
                        id:null,
                        html:null,
                        titre: null
                    };
                    this.files = [];
                }
            })
        }, 
        async handleFile(e) {
            try {
                const files = Array.from(e.target.files);
                this.load = true;
                for (const file of files) {
                    const base64 = await this.readFileAsBase64(file);
                    this.files.push({
                        name: file.name,
                        base64: base64,
                        size: file.size
                    });
                }
                this.load = false;                
            } catch (error) {
                console.error(error);
            }
        },
        readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = () => {
                    reject(new Error('Une erreur s\'est produite lors de la lecture du fichier en base64.'));
                };
                reader.readAsDataURL(file);
            });           
        },
        size(size) {
            if(size === 0) {
                return '0 octets';
            }
            const units = ['octets', 'Ko', 'Mo', 'Go'];
            const k = 1024;
            const i = Math.floor(Math.log(size) / Math.log(k));
            return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + units[i];
        },
        removeFile(i){
            this.files.splice(i, 1);
        },
    },
    mounted(){        
    }
}
</script>
<style scoped>
.side-messages{
    height: 950px;
    overflow: scroll;
}
</style>