<template>
<b-card class="card-custom mt-3">
  <b-card-text class="font-weight-bold h5">Ancien contrat</b-card-text>
  <b-row v-if="![4,6].includes(obj.ancien.type_id)">
    <b-col md="4">
      <b-form-group label="Recherche rapide">
        <Input v-model="resiliation_id" type="select-model" :options="resiliations"/>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Numero d'adherent">
        <Input v-model="obj.ancien.numero_adherent" :required="true" type="text"/>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Ancienne">
        <Input v-model="obj.ancien.raison_sociale" type="text"/> 
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Nouvelle">
        <Input v-model="obj.ancien.nouvelle_mutuelle" type="text"/>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Siren">
        <Input v-model="obj.ancien.siren" type="text"/>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Adresse">
        <Input v-model="obj.ancien.adresse" type="text"/>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group label="Code postal">
        <Input v-model="obj.ancien.cp" type="text"/>
      </b-form-group>
    </b-col> 
    <b-col md="4">
      <b-form-group label="Ville">
        <Input v-model="obj.ancien.ville" type="text"/>
      </b-form-group>
    </b-col>            
  </b-row>
  <b-row v-if="motif">
    <b-col md="4">
      <b-form-group label="Motif de resiliation">
        <Input v-model="obj.ancien.type_id" type="select-model" :options="$api.params.ResiliationType" :required="true" />                  
      </b-form-group>
    </b-col>
    <b-col md="4" v-if="![4,6].includes(obj.ancien.type_id)">
      <b-form-group label="Date d'échéance">
        <input type="date" class="form-control" required v-model="obj.ancien.date_fin_contrat" :max="max_d_effet">
      </b-form-group>
    </b-col>
  </b-row>
</b-card>
</template>
<script>
import Input from '@/components/InputForm';
export default {
  props: {    
    obj:{
      type: Object,
      required: true
    },
    motif:{
      type: Boolean,
      required:false,
      default:true
    }
  },
  components: {   
    Input 
  },
  name: "AncienContrat",
  computed:{
    max_d_effet(){
      var next =  this.$api.moment().add(1, 'Y');
      return next.format('YYYY-MM-DD');
   }
  },
  watch:{    
    resiliation_id(v){
      var check = this.resiliations.findID(v);
      if(v === null || check === undefined){
        this.obj.ancien.raison_sociale = null;
        this.obj.ancien.adresse = null;
        this.obj.ancien.ville = null;
        this.obj.ancien.cp = null;
      }else{
        this.obj.ancien.raison_sociale = check.raison_sociale;
        this.obj.ancien.adresse = check.adresse;
        this.obj.ancien.ville = check.ville;
        this.obj.ancien.cp = check.cp;
      }
   },
  },
  data(){
    return {
      resiliation_id: null,
      resiliations:[]
    }
  },
  methods: {    
  },
  mounted() {
    this.$api.ajax('/contrat/resiliation', null, res => {
      if(res.status === true){
        this.resiliations = res.data;
      }
    })
  },
}
</script>
<style lang="scss" scoped>

</style>
