<template>
    <b-input-group >
        <template #append>
            <b-button variant="light-primary" class="btn-icon"  @click="$api.call(valueLocal)" :size="edit ? 'md' : 'sm'"><i class="fa fa-phone"></i></b-button>
        </template>
        <b-form-input :class="edit ? '' : 'telephone-no-edit'" :size="edit ? 'md' : 'sm'" v-model="valueLocal" :readonly="!edit"></b-form-input>
    </b-input-group>
</template>
<script>
    export default {
        name: "InputForm",
        props: {
            value: {
                required: true
            },            
            edit: {
                type: Boolean,
                required: false
            }
        },
        model: {
            prop: 'value',
            event: 'inputChange'
        },
        computed: {
            valueLocal: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('inputChange', value)
                }
            }
        },
        methods: {},
        mounted() {}
    }
</script>
<style lang="scss" scoped="scoped">
.telephone-no-edit {
  background: none;
  border:none;
}
</style>