<template>
  <b-col v-if="ready" :md="main.md" :cols="main.sm">
    <b-form-group :label="main.label+':'" v-if="main.type != 'checkbox'">
      <Input v-model="obj[field]" :type="main.type" :placeholder="main.label" :options="modelOptions" :required="main.required" :edit="edit"/>
    </b-form-group>
    <Input v-else v-model="obj[field]" :type="main.type" :placeholder="main.label" :options="modelOptions" :edit="edit"/>
  </b-col>
</template>
<script>
import Input from './InputForm';
export default {
  props: {
    model: {
      type: String,
      required: true
    },
    field:{
      type: String,
      required: true
    },
    obj:{
      type: Object,
      required: true
    }
  },
  components: {
    Input
  },
  name: "FieldForm",
  data(){
    return {
      ready: true
    }
  },
  computed:{
    main(){
      var model = this.$api.params.Model.find(m => m.titre === this.model);
      if(model === undefined){
        //this.ready = false;
        return false;
      }
      var check = model.fields.find(f => f.key === this.field);
      if(check === undefined){
        //this.ready = false;
        return false;
      }
      return check;
    },
    modelOptions(){
      var check = this.main.model;
      var check1 = this.$api.params[check];
      if(check === undefined){
        return [];
      }
      return check1;
    },
    edit(){  
      if(this.main === false){
        console.log(this.field);
      }      
      return !this.main.edit.includes(this.$api.user.role_id);
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
